import { Text } from "./text";

type Props = {
  error?: string;
};

export const ErrorField = ({ error }: Props) => {
  return (
    !!error && (
      <Text
        size="sm"
        variant="error"
        className="text-error-500 text-4xs leading-3 pt-2 pb-1"
      >
        {error}
      </Text>
    )
  );
};
